import { useContext } from 'react'
import { ThemeContext as StyledThemeCopntext } from 'styled-components'
import { ThemeContext } from 'contexts/ThemeContext'

const useTheme = () => {
  const { isDark, toggleTheme } = useContext(ThemeContext)
  const theme = useContext(StyledThemeCopntext)

  // const primaryColor = '#31D0AA'
  const primaryColor = '#48226c'
  const hoverColor = '#7e6cbe'
  const dark = '#0d111c'
  const light = '#cdd1d7'
  const darkPrimary = '#5c48a7'
  const textColor = hoverColor
  const background = "transparent"

  if (isDark) {
    theme.colors.text = light
    theme.colors.secondary = primaryColor
    theme.colors.textSubtle = primaryColor
    theme.colors.overlay = primaryColor
    theme.colors.primary = primaryColor
    theme.colors.primaryBright = primaryColor
    theme.colors.background = 'transparent'
    theme.colors.borderColor = `rgba(0,0,0,0.5)`
    theme.colors.input = `rgba(0,0,0,0.5)`
    theme.colors.overlay = `rgba(0,0,0,0.9)`
    theme.card.background = dark
    theme.card.boxShadow = '0 0 30px 0 rgba(0, 0, 0, 0.3)'
    theme.shadows.focus = primaryColor
    theme.button.subtle.color = `#fff`
    theme.button.subtle.background = primaryColor
    theme.button.tertiary.color = `#fff`
    theme.button.tertiary.background = primaryColor
    theme.button.tertiary.backgroundHover = hoverColor
    theme.button.primary.background = primaryColor
    theme.button.primary.color = `#fff`
    theme.button.primary.backgroundActive = hoverColor
    theme.button.primary.backgroundHover = hoverColor
    theme.button.primary.boxShadow = 'none'
    theme.button.primary.boxShadowActive = 'none'
    theme.button.secondary.border = `2px solid ${primaryColor}`
    theme.button.secondary.borderColorHover = primaryColor
    theme.button.secondary.color = primaryColor
    theme.button.text.color = primaryColor
    theme.modal.background = dark
  } else {
    theme.colors.background = '#ffffff'
    theme.colors.text = textColor
    theme.colors.secondary = primaryColor
    theme.colors.textSubtle = primaryColor
    theme.colors.background = background
    theme.button.primary.background = primaryColor
    theme.button.primary.backgroundActive = primaryColor
    theme.button.primary.backgroundHover = hoverColor
    theme.button.primary.boxShadow = '0 8px 6px -6px rgba(0, 0, 0, .3)'
    theme.button.primary.boxShadowActive = '0 0 35px rgba(0, 0, 0, .2)'
    theme.colors.overlay = `rgba(0,0,0,0.9)`
    theme.colors.primary = darkPrimary
    theme.button.text.color = darkPrimary
    theme.button.secondary.color = darkPrimary
    theme.button.secondary.border = `2px solid ${darkPrimary}`
    theme.button.secondary.borderColorHover = primaryColor
    theme.button.tertiary.background = primaryColor
    theme.button.tertiary.color = '#ffffff'
    theme.button.tertiary.backgroundHover = darkPrimary
  }

  return { isDark, toggleTheme, theme }
}

export default useTheme
