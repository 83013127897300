import React, { useEffect, Suspense, lazy } from 'react'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import { useWallet } from '@binance-chain/bsc-use-wallet'
import { ResetCSS } from '@pancakeswap-libs/uikit'
import BigNumber from 'bignumber.js'
import { useFetchPublicData } from 'state/hooks'
import PageComingSoon from 'views/PageComingSoon'
import GlobalStyle from './style/Global'
import Menu from './components/Menu'
import PageLoader from './components/PageLoader'
import BannerHeader from './components/BannerHeader'

// Route-based code splitting
// Only pool is included in the main bundle because of it's the most visited page'
const Home = lazy(() => import('./views/Home'))
const Farms = lazy(() => import('./views/Farms'))
// const Lottery = lazy(() => import('./views/Lottery'))
// const Pools = lazy(() => import('./views/Pools'))
// const Ifos = lazy(() => import('./views/Ifos'))
const NotFound = lazy(() => import('./views/NotFound'))
const Buy = lazy(() => import('./views/Buy'))
// const Trade = lazy(() => import('./views/BuyNow'))
const AddLiquidity = lazy(() => import('./views/AddLiquidity'))
// const Launchpad = lazy(() => import('./views/Teaser'))
// const KingOfElephants = lazy(() => import('./views/KingOfElephants'))
const Nft = lazy(() => import('./views/Nft'))

// This config is required for number formating
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

const App: React.FC = () => {
  const { account, connect } = useWallet()
  useEffect(() => {
    if (!account && window.localStorage.getItem('accountStatus')) {
      connect('injected')
    }
  }, [account, connect])

  useFetchPublicData()

  return (
    <Router>
      <ResetCSS />
      <GlobalStyle />
      <Menu>
        <Suspense fallback={<PageLoader />}>
          <Switch>
            <Route path="/" exact>
              {/* <Home /> */}
              <BannerHeader />
              <Farms />
            </Route>
            {/* <Route path="/farms">
              <Farms />
            </Route>
            <Route path="/goldpool">
              <Farms tokenMode />
            </Route>
            <Route path="/diamondpool">
              <Farms tokenMode diamondFarm />
            </Route>
            <Route path="/buy">
              <Buy />
            </Route>
            <Route path="/liquidity">
              <AddLiquidity />
            </Route>
            <Route path="/junglepools">
              <Pools />
            </Route>
            <Route path="/buy">
              <Trade />
            </Route>
            <Route path="/ido">
              <PageComingSoon />
            </Route>
            <Route path="/nft">
              <Launchpad />
            </Route>
            <Route path="/kingOfElephants">
              <KingOfElephants />
            </Route>
            <Route path="/lottery">
              <Lottery />
            </Route>
            <Route path="/ifo">
              <Ifos />
            </Route>
            <Route path="/nftgallery">
              <PageComingSoon />
              <Nft />
            </Route>
            <Route path="/syrup">
              <Redirect to="/pools" />
            </Route> */}
            <Route component={NotFound} />
          </Switch>
        </Suspense>
      </Menu>
    </Router>
  )
}

export default React.memo(App)
