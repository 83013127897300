import React from 'react'
import styled from 'styled-components'
import Page from './layout/Page'
import AssetCarousel from './AssetCarousel'

const BannweWrapper = styled(Page)`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
`

const BannerTitle = styled.div`
  font-size: 45px;
  font-weight: 600;
  text-transform: capitalize;
  color: ${(props) => props.theme.colors.primary};
`

const BannerSubTitle = styled.div`
  font-size: 18px;
  text-transform: capitalize;
`

const BannerHeader = () => {
  return (
    <>
      <BannweWrapper>
        <BannerTitle>Stack To Get Rare NFT</BannerTitle>
        <BannerSubTitle>Choose an asset to Stake, Host or Monitor to get started</BannerSubTitle>
      </BannweWrapper>
      <AssetCarousel />
    </>
  )
}

export default BannerHeader
