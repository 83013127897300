/* eslint-disable react/no-array-index-key */
import React from 'react'
import styled from 'styled-components'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'

export const Slide = styled.div``

export const SlideImage = styled.img`
  padding: 8px 8px 8px 0;
  border-radius: 32px;
`

const assets = [
  { key: 1, url: 'images/assets/asset1.png', name: 'asset1' },
  { key: 2, url: 'images/assets/asset2.png', name: 'asset2' },
  { key: 3, url: 'images/assets/asset3.png', name: 'asset3' },
  { key: 4, url: 'images/assets/asset4.png', name: 'asset4' },
  { key: 5, url: 'images/assets/asset5.png', name: 'asset5' },
  { key: 6, url: 'images/assets/asset6.png', name: 'asset6' },
  { key: 7, url: 'images/assets/asset7.png', name: 'asset7' },
]

const AssetCarousel = () => {
  const settings = {
    infineLoop: true,
    autoplay: true,
    slidesToShow: 6,
    speed: 4000,
    autoplaySpeed: 0,
    cssEase: 'linear',
    arrows: false,
    dots: false,
  }
  return (
    <Slider {...settings}>
      {assets.map((asset) => {
        return (
          <Slide key={asset.key}>
            <SlideImage src={asset.url} alt={asset.name} />
          </Slide>
        )
      })}
    </Slider>
  )
}

export default AssetCarousel
